import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";

import { breakpoints } from "../styles/layouts";

import ArticleCard from "../components/article-card";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        sort: { fields: [date], order: DESC},
        limit: 3
      ) {
        edges {
          node {
            id
            slug
            uri
            title
            content
            excerpt
            link
            date
            featuredImage {
              node {
                id
                mediaItemUrl
                mediaDetails {
                  file
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div css={BlogArticlesStyle}>
      {data.allWpPost.edges.map(({ node }, i) => (
        <ArticleCard key={i} node={node} />
      ))}
    </div>
  )
}

const BlogArticlesStyle = css`
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: nowrap;

  @media ${breakpoints.sm} {
    margin: 0 -1rem;
  }

  @media ${breakpoints.xs} {
    margin: 0 0;

    flex-direction: column;
    flex-wrap: nowrap;
  }
`;
