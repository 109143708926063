import React from "react";
import { DateTime } from "luxon";
import Imgix from "react-imgix";
import { css } from "@emotion/react";
import { Link } from "gatsby";

import Typography from '../styles/typography';
import { breakpoints } from "../styles/layouts";
import { NewBadge } from "../components/badge";

export default ({ node }) => {

  const now = DateTime.local();
  const postDate = DateTime.fromISO(node.date);
  const isNewest = (postDate.plus({ days: 3 }) > now);

  return (
    <div css={ArticleCardStyle}>
      <Link to={`/blog${node.uri}`} className="pointer">
        <div className="overlay">
          { isNewest &&  <NewBadge left="1rem" right="1rem" /> }
          <h2 className="title" dangerouslySetInnerHTML={{ __html: node.title }} />
          {/* <div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
          <time className="date" dateTime={node.date}>
            <span className="date-day">{postDate.day}</span>
            <span className="date-year">{postDate.year}</span>-
            <span className="date-month">{postDate.month}</span>
          </time>
        </div>
        <div className="background">
          { node.featuredImage && <Imgix src={`${process.env.GATSBY_WP_IMGIX_URL}/${node.featuredImage.node.mediaDetails.file}`} className="eyecatch" width={640} imgixParams={{ ar: "1:1", fit: "crop" }} /> }

          { !node.featuredImage && <span className="eyecatch" /> }
        </div>
      </Link>
    </div>
  );
}

const ArticleCardStyle = css`

  position: relative;
  width: 320px;
  margin: 0 1.5rem;

  @media ${breakpoints.xs} {
    width: 100%;
    margin: 1rem 0;
  }

  @media ${breakpoints.sm} {
    margin: 0 1rem;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .pointer {
    transition:
      box-shadow .2s ease-in-out,
      transform .2s ease-in-out;
    position: relative;
    display: block;
    border-radius: 12px;

    box-shadow:
      0 0.1px 0.4px rgba(0, 0, 0, 0.024),
      0 0.3px 1px rgba(0, 0, 0, 0.035),
      0 1.1px 2.4px rgba(0, 0, 0, 0.046),
      0 4px 8px rgba(0, 0, 0, 0.07)
    ;

    &:hover {
      transform: translateY(-4px);
      text-decoration: none;
      box-shadow:
        0 0.2px 1.1px rgba(0, 0, 0, 0.024),
        0 1px 3px rgba(0, 0, 0, 0.035),
        0 3.3px 7.2px rgba(0, 0, 0, 0.046),
        0 12px 24px rgba(0, 0, 0, 0.07)
      ;
    }

    &:active {
      transform: translateY(-2px);

      box-shadow:
        0 0.1px 0.4px rgba(0, 0, 0, 0.024),
        0 0.3px 1px rgba(0, 0, 0, 0.035),
        0 1.1px 2.4px rgba(0, 0, 0, 0.046),
        0 4px 8px rgba(0, 0, 0, 0.07)
      ;
    }
  }

  .overlay {
    transition: background-color .2s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;

    color: #fff;
    text-align: center;
    text-shadow: 0 1px 4px rgba(0,0,0,.5);

    background: rgba(0,0,0,.5);
    border-radius: 12px;
  }
  .pointer:hover > .overlay {
    background: rgba(0,0,0,.3);
  }

  .background {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    z-index: 0;
    border-radius: 12px;

    & > .eyecatch {
      display: block;
      width: 100%;
      height: 100%;
      transition: transform .2s ease-in-out;
      background: #eee;
    }
  }
  .pointer:hover > .background > .eyecatch {
    transform: scale(1.1);
  }

  .title {
    text-align: center;
    ${Typography.h4};
  }

  .date {
    font-weight: bold;
    color: #eee;

    .date-year {

    }

    .date-month {
    }

    .date-day {
      display: block;
      font-size: 2.5rem;
      line-height: 1.25;
      color: #fff;
    }
  }

  .exerpt {

  }
`;
